import { post } from '@/utils/request'

//获取列表
export function getList(data) {
    return post('psi/Lend/list', data)
}
//修改分类
export function getEdit(data) {
    return post('psi/Lend/return', data)
}
//分类下拉
export function getOption(data) {
    return post('psi/warehouse/Warehouse', data)
}

