<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">归还</div>
        <div class="title" v-show="!openState">归还</div>
      </div>
      <el-form :model="form">
        <el-form-item label="库位" :label-width="formLabelWidth">
          <el-cascader
            :options="options"
            :props="optionProps"
            v-model="form.sl_id"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="关联任务" :label-width="formLabelWidth">
          <el-cascader
            placeholder="关联任务"
            :options="optionsTask"
            :props="optionPropsTask"
            v-model="form.task_id"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="入库图" :label-width="formLabelWidth">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="3"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传入库图</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :append-to-body="true">
      <div slot="title" class="dialog-footer">
        <div class="title">删除库位</div>
      </div>
      <span>{{ userdelinfo.seat }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <div class="menu-box">
            <h2>员工借出表</h2>
            <div class="box-bd">
                <el-row :gutter="20">
        <el-col :span="5">
          <el-select
            v-model="getInfo.state"
            clearable
            placeholder="请选择"
            @change="catalogue"
          >
            <el-option
              v-for="item in optionsSeach"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button type="warning" @click="dialogOpen">查看日志</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="mp_name" label="名称"></el-table-column>
        <el-table-column
          prop="quantity"
          label="数量"
          width="80px"
        ></el-table-column>
        <el-table-column prop="state" label="归还状态" width="80px">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.state == 1">未归还</el-tag>
            <el-tag v-show="scope.row.state == 2">已归还</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="借出时间"></el-table-column>
        <el-table-column prop="repay_time" label="归还时间"></el-table-column>
        <el-table-column prop="acc_state" label="状态">
          <template slot-scope="scope" width="80px">
            <el-tag v-show="scope.row.acc_state == 0">商品</el-tag>
            <el-tag v-show="scope.row.acc_state == 1">配件</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="u_name" label="借走人信息"></el-table-column>
        <el-table-column prop="e_name" label="归还人信息"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="success"
              icon="el-icon-house"
              @click="editOpen(scope.row)"
            >归还</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        :pager-count="15"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
            </div>
    </div>
     
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getList, getEdit, getOption } from "@/api/psi/lend.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      optionPropsTask: {
        value: "id",
        label: "name",
        children: "son",
        disabled: "state",
      },
      optionsTask: [],
      dialogLog: {
        state: false,
      },
      token: {
        "Accept-Token": window.sessionStorage.getItem("token"),
      },
      openState: true,
      //配置
      optionProps: {
        value: "id",
        label: "seat",
        children: "sub",
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        state: "",
        info: "",
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: "一级",
        },
        {
          id: 2,
          name: "二级",
        },
      ],
      optionsSeach: [
        { value: 1, label: "未归还" },
        { value: 2, label: "已归还" },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        img: [],
        sl_id: [],
        task_id: [],
      },
      fileList: [],
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dialogOpen() {
      this.dialogLog.state = true;
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value);
      this.getInfo.page_num = value;
      this.catalogue();
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      this.catalogue();
    },
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      console.log(response);
      this.form.img.push(response.data.url);
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      console.log(fileList);
      this.form.img = [];
      fileList.forEach((item) => {
        if (item.response) {
          this.form.img.push(item.response.data.url);
        } else {
          this.form.img.push(item.url);
        }
      });
      console.log(this.form);
    },
    //打开添加
    addOpen() {
      this.openState = true;
      this.dialogFormVisible = true;
    },
    //打开编辑
    editOpen(row) {
      this.openState = false;
      this.dialogFormVisible = true;
      this.form.id = row.id;
      console.log(this.form);
    },
    //编辑打开
    async setEdit() {
      let info = await getEdit(this.form);
      this.judge(info);
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true;
      this.userdelinfo = row;
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.dialogVisible = false;
        this.catalogue();
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        img: [],
        sl_id: [],
        task_id: [],
      };
      console.log("close");
    },
    //目录接口
    async catalogue() {
      let info = await getList(this.getInfo);
      console.log(info.data.data);
      let options = await getOption();
      let optionsTask = await this.$getOptionTask();
      this.optionsTask = optionsTask.data.data;
      this.options = options.data.data;
      this.tableData = info.data.data;
      this.exp.count = info.data.exp.count;
      this.exp.num = info.data.exp.num;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue();
    
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>